import Vue from 'vue';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import ApiStore from "@/assets/js/services/ApiStore";

/**
 * Module state
 */
interface State {
    caller:string;
    deviceConfig:any;
    isInjected:boolean|string;
}

const moduleState: State = {
    caller: localStorage.getItem('caller')||'',
    deviceConfig: window.localStorage.getItem('deviceConfig') ? window.localStorage.getItem('deviceConfig') : '',
    isInjected:  window.localStorage.getItem('isInjected') || false,

};

/**
 * Module getters
 */
const moduleGetters: GetterTree<State, any> = {
    caller(state: State) {
        return state.caller || '';
    },
    async deviceConfig(state: State) : Promise<any> {
        if (state.deviceConfig) {
            try {
                const item = JSON.parse(state.deviceConfig);
                if (item) {
                    try {
                        await ApiStore.setItem("deviceConfig", JSON.stringify(item));
                        state.deviceConfig = "";
                    }catch(e:any) {

                    }
                }
            } catch (e: any) {
                state.deviceConfig = "";
            }

        }
        if(state.deviceConfig) {
            return JSON.parse(state.deviceConfig);
        }else {
            let item = undefined;
            try {
                item = await ApiStore.getItem("deviceConfig");
            } catch (e: any) {
            }
            if (item) {
                return JSON.parse(item)
            } else {
                return {useExternalScanner: false, codeType: 0};
            }
        }
        // if (state.deviceConfig) {
        //     return JSON.parse(state.deviceConfig);
        // }
        // return {};
    },
    isInjected(state:State): boolean {
        return (state.isInjected.toString() === "true");
    }
};

/**
 * Module mutations
 */
const moduleMutations: MutationTree<State> = {
    setCaller(state: State, caller: string) {
        state.caller = caller;
    },
    async setDeviceConfig(state: State, deviceConfig: any) {
        try{
            await ApiStore.setItem('deviceConfig',JSON.stringify(deviceConfig));
            state.deviceConfig="";
        }catch (e:any) {
            state.deviceConfig = JSON.stringify(deviceConfig);
        }

        //window.localStorage.setItem('deviceConfig',state.deviceConfig);
        //state.deviceConfig = JSON.stringify(deviceConfig);
    },
    setIsInjected(state:State, param:boolean) {
        state.isInjected = param;
    },
};

/**
 * Module actions
 */
const moduleActions: ActionTree<State, any> = {

};

/**
 * Construct Module
 */
const module: Module<State, any> = {
    namespaced: true,
    state: moduleState,
    getters: moduleGetters,
    mutations: moduleMutations,
    actions: moduleActions,
};
export default module;
