
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import { routes } from '@/router';
import LogoutDialog from '@/components/LogoutDialog.vue';

@Component({ components: { LogoutDialog } })
export default class Menu extends Vue {
    @Prop({ default: false })
    private showLogo!: boolean;
    private routes = routes;
    private get showLogOut(): boolean {
        return !this.$store.getters["Base/caller"] && !this.$store.getters["Base/isInjected"];
    }

    private resetCaller() : void {


        this.$store.commit("Base/setCaller","");


    }
    private getCallerUrl() {
        let caller = this.$store.getters['Base/caller'];
        if (!caller) {
            if(this.$store.getters['Base/isInjected']) {
                return "/end";
            }
            return "#";
        }
        return caller + decodeURIComponent("?bid=" + this.$ports.auth.getBranchId());
    }
}
