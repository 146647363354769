import type { AuthRepository } from '../adapter/AuthRepository';
import type { LoginRepository } from '../adapter/LoginRepository';
import axios from "axios";

export class AuthService {

    private readonly authRepository!: AuthRepository;
    private readonly loginRepository!: LoginRepository;
    private readonly appId!: string;

    constructor(params: {
        authRepository: AuthRepository
        loginRepository: LoginRepository
        appId: string,
    }) {
        this.authRepository = params.authRepository;
        this.loginRepository = params.loginRepository;
        this.appId = params.appId;
    }

    public async login(): Promise<boolean> {
        if (await this.authRepository.isLoggedIn()) {
            return true;
        }
        const response = this.loginRepository.login({
            appId: this.appId,
            serviceUrl: this.authRepository.getServiceUrl(),
        });
        return this.authRepository.testCredentials({
            serviceUrl: response?.service,
            authToken: response?.token,
            userId: response?.userId,
            branchId: response?.branchId,
        });
    }

    public logout() {
        this.loginRepository.logout({
            appId: this.appId,
            serviceUrl: this.getServiceUrl(),
        });
    }

    public async isLoggedIn(): Promise<boolean> {
        return this.authRepository.isLoggedIn();
    }

    public getServiceUrl(): string {
        return this.authRepository.getServiceUrl();
    }

    public getAuthToken(): string {
        return this.authRepository.getAuthToken();
    }

    public getUserId(): string {
        return this.authRepository.getUserId();
    }

    public getBranchId(): string {
        return this.authRepository.getBranchId();
    }

    public async getBranches(): Promise<{Nummer: number, Name:string}[]> {
        const userId = this.authRepository.getUserId();
            const response = await axios.post(`${this.authRepository.getServiceUrl()}/Authentication/Tbl_Filialen`,{ UserID: userId});
            return response.data.Tbl_Filialen_response.Tbl_Filialen;
    }

    public async switchBranch(newBranchId:number): Promise<boolean> {
        const sid = this.getAuthToken();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: newBranchId
            }
            const response = await axios.post(`${this.getServiceUrl()}/Authentication/set_Filiale`, data);
            if (response.data.set_Filiale_response?.ServiceErrors) {
                throw new Error(response.data.set_Filiale_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
            return response.data.set_Filiale_response === null;
        } else {
            return Promise.reject();
        }
    }
    public async sessionInfo(sid: string): Promise<any> {
        const response = await axios.post(`${this.getServiceUrl()}/Authentication/getSessionInformation`,
            {
                SID: sid
            });

        if (response && response.data && response.data.getSessionInformation_response) {
            if (response.data.getSessionInformation_response.ServiceErrors) {
                throw new Error(response.data.getSessionInformation_response.ServiceErrors[0].ERRORDESCRIPTION);
            }else {

                return response.data.getSessionInformation_response;
            }

            // eslint-disable-next-line no-param-reassign

        } else {
            return undefined
        }
    }
}
