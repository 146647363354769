import { AuthService } from './services/AuthService';
import axios from "axios";

export class AuthPort {

    private readonly authService: AuthService;

    constructor(params: { authService: AuthService }) {
        this.authService = params.authService;
    }

    /**
     * Checks the route for an auth parameter, that passes the login information from the external
     * login page to this application. Afterwards tests the credentials and starts a keep alive
     * loop. If the credentials are invalid, navigates to external login page.
     */
    public async login(): Promise<boolean> {
        return this.authService.login();
    }

    /**
     * Logs the user out and navigates to external login page
     */
    public logout(): void {
        this.authService.logout();
    }

    public getServiceUrl(): string {
        return this.authService.getServiceUrl();
    }

    public getAuthToken(): string {
        return this.authService.getAuthToken();
    }

    public getUserId(): string {
        return this.authService.getUserId();
    }

    public getBranchId(): string {
        return this.authService.getBranchId();
    }

    public isLoggedIn(): Promise<boolean> {
        return this.authService.isLoggedIn();
    }

    public async getBranches(): Promise<{Nummer: number, Name:string}[]> {
        return await this.authService.getBranches();
    }

    public async switchBranch(newBranchId:number): Promise<boolean> {
        return this.authService.switchBranch(newBranchId);
    }

    public async getSessionInfo(sid:string): Promise<any> {
        return this.authService.sessionInfo(sid);
    }
}
