
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({components: {}})
export default class StartApp extends Vue {
    private errorMessage:string="Leider hat etwas nicht funktioniert."
    private success: boolean = true;

    async mounted() {
        const query = {...this.$route.query};
        if (query.sid) {
            if(query.surl && query.surl !== ''){
                this.$store.commit('Auth/setServiceUrl', query.surl);
            }
            try{
                const sessionInfo = await this.$ports.auth.getSessionInfo(query.sid.toString());
                if(sessionInfo){
                    this.$store.commit("Base/setIsInjected", true);
                    this.$store.commit("Auth/setAuthToken", query.sid.toString());
                    this.$store.commit("Auth/setBranchId", sessionInfo.Filiale.ID);
                    this.$store.commit("Auth/setUserId", sessionInfo.User.ID);
                    this.$store.commit("Auth/setLoggedIn", true);
                    await this.$router.push("/");
                    this.success = true;
                }
            }catch(e:any) {
                console.log(e);
                this.success = false;
                this.errorMessage = e;
            }

        } else {
            this.success = false;
        }
    }
}