
import {Component, Vue} from "vue-property-decorator";

@Component({components: {}})
export default class End extends Vue {
    private message:string = "Die Anwendung wird geschlossen..."

    async mounted() {
        this.$store.commit("Auth/setAuthToken",'');
        this.$store.commit("Base/setIsInjected",false);
        this.$ports.auth.logout();
        await this.$router.replace("/");
    }
}