import Vue from 'vue';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

/**
 * Module state
 */
interface State {
    userId: null|string;
    authToken: null|string;
    serviceUrl: null|string;
    loggedIn: boolean;
    branchId: string | null;
}

const moduleState: State = {
    userId: null,
    authToken: null,
    serviceUrl: null,
    loggedIn: false,
    branchId: localStorage.getItem('branchId')
};

/**
 * Module getters
 */
const moduleGetters: GetterTree<State, any> = {
    userId(state: State) {
        return state.userId || '';
    },
    authToken(state: State) {
        return state.authToken || '';
    },
    serviceUrl(state: State) {
        return state.serviceUrl || '';
    },
    loggedIn(state: State) {
        return state.loggedIn;
    },
    branchId(state: State): string {
        return state.branchId || '0';
    },
};

/**
 * Module mutations
 */
const moduleMutations: MutationTree<State> = {
    setUserId(state: State, userId: string) {
        state.userId = userId;
    },
    setAuthToken(state: State, authToken: string) {
        state.authToken = authToken;
    },
    setServiceUrl(state: State, serviceUrl: string) {
        state.serviceUrl = serviceUrl;
    },
    setLoggedIn(state: State, loggedIn: boolean) {
        state.loggedIn = loggedIn;
    },
    setBranchId(state: State, branchId: string): void {
        state.branchId = branchId;
        localStorage.setItem('branchId', branchId);
    },
};

/**
 * Module actions
 */
const moduleActions: ActionTree<State, any> = {

    async login(context): Promise<void> {
        const loggedIn = await Vue.ports.auth.login();

        context.commit('setLoggedIn', loggedIn);
    },

    async logout(context): Promise<void> {
        Vue.ports.auth.logout();
        context.commit('setLoggedIn', false);
    },

    async keepAlive(context): Promise<boolean> {
        const loggedIn = Vue.ports.auth.isLoggedIn();
        context.commit('setLoggedIn', loggedIn);
        return loggedIn;
    },
};

/**
 * Construct Module
 */
const module: Module<State, any> = {
    namespaced: true,
    state: moduleState,
    getters: moduleGetters,
    mutations: moduleMutations,
    actions: moduleActions,
};
export default module;
