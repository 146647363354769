import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import StartApp from "@/views/StartApp.vue";
import End from "@/views/End.vue";
const Cards = (resolve: (...modules: any[]) => void) => require(['../views/cards/Cards.vue'], resolve);
const Reports = (resolve: (...modules: any[]) => void) => require(['../views/reports/Reports.vue'], resolve);
const Transactions = (resolve: (...modules: any[]) => void) => require(['../views/transactions/Transactions.vue'], resolve);
const CardDistribute = (resolve: (...modules: any[]) => void) => require(['../views/cards/CardDistribute.vue'], resolve);
const Administration = (resolve: (...modules: any[]) => void) => require(['../views/administration/Administration.vue'], resolve);
const AdminCustomers = (resolve: (...modules: any[]) => void) => require(['../views/administration/Customers.vue'], resolve);
const AdminCards = (resolve: (...modules: any[]) => void) => require(['../views/administration/CardTypes.vue'], resolve);
const About = (resolve: (...modules: any[]) => void) => require(['../views/About.vue'], resolve);

const DeviceConfig = (resolve: (...modules: any[]) => void) => require(['../views/deviceConfig/DeviceConfig.vue'], resolve);

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
    {
        path: '/cards',
        name: 'Cards',
        component: Cards,
        meta: {
            loginRequired: true,
            pageTitle: 'Karten',
            pageIcon: 'fa-id-card',
        },
    },
    {
        path: '/transactions',
        name: 'Transactions',
        component: Transactions,
        meta: {
            loginRequired: true,
            pageTitle: 'Transaktionen',
            pageIcon: 'fas fa-table-list',
        },
    },
    {
        path: '/reports',
        name: 'Reports',
        component: Reports,
        meta: {
            loginRequired: true,
            pageTitle: 'Berichte',
            pageIcon: 'fa-file-lines',
        },
    },
    {
        path: '/administration',
        name: 'Admin',
        component: Administration,
        meta: {
            loginRequired: true,
            pageTitle: 'Verwaltung',
            pageIcon: 'fa-gears',
        },
    },
];

const otherRoutes: Array<RouteConfig> = [
    {
        path: '*',
        redirect: '/cards'
    },
    {
        path: '/About',
        name: 'About',
        component: About,
        meta: {
            pageTitle: 'About',
            pageIcon: 'fa-question',
        },
    },
    {
        path: '/administration/customers',
        name: 'AdminCustomers',
        component: AdminCustomers,
        meta: {
            loginRequired: true,
            pageTitle: 'Verwaltung / Kunden',
        },
    }, {
        path: '/administration/cards',
        name: 'AdminCards',
        component: AdminCards,
        meta: {
            loginRequired: true,
            pageTitle: 'Verwaltung / Kartentypen',
        },
    },{
        path: '/deviceConfig',
        name: 'DeviceConfig',
        component: DeviceConfig,
        meta: {
            loginRequired: true,
            pageTitle: 'Geräteeinstellungen',
        },
    }, {
        path: '/cards/distribute',
        name: 'CardDistribute',
        component: CardDistribute,
        meta: {
            loginRequired: true,
            pageTitle: 'Karten / Karte ausgeben',
        },
    },{
        path: '/start',
        name: 'start',
        component: StartApp,
        meta: {
            loginRequired: false,
            pageTitle: 'Start',
        }},{
        path: '/end',
        name: 'end',
        component: End,
        meta: {
            loginRequired: false,
            pageTitle: 'By bye.',
        },
    },
];
const router = new VueRouter({
    mode: 'history',
    routes: routes.concat(otherRoutes),
});

export default router;
