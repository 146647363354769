import _Vue, { PluginObject } from 'vue';
import { Store } from 'vuex';
import { AuthPort, AuthPortFactory } from '@/modules/auth/index';
import { AuthModuleAdapter } from '@/store/auth/AuthModuleAdapter';
import {LicensingPort, LicensingPortFactory} from "@/modules/licensing";
import {CardPort, CardPortFactory} from "@/modules/cards";
import {CardActionsPort, CardActionsPortFactory} from "@/modules/cardActions";

interface Ports {
    auth: AuthPort;
    license: LicensingPort;
    card:CardPort;
    cardActions:CardActionsPort;
}

const portsPlugin: PluginObject<any> = {
    install(vue: typeof _Vue, store: Store<any>): void {
        // Instantiate ports
        const authPort: AuthPort = AuthPortFactory.createAuthPort({
            appId: process.env.VUE_APP_APPID || '',
            loginServiceUrl: process.env.VUE_APP_LOGIN_URL || '',
            store: new AuthModuleAdapter(store),
        });

        const licensingPort: LicensingPort = LicensingPortFactory.createLicensingPort({
            getServiceUrl: () => authPort.getServiceUrl(),
            getAuthToken: () => authPort.getAuthToken(),
            getUserId: () => authPort.getUserId(),
            getBranchId: () => authPort.getBranchId(),
        });
        const cardPort: CardPort = CardPortFactory.createCardsPort({
            getServiceUrl: () => authPort.getServiceUrl(),
            getAuthToken: () => authPort.getAuthToken(),
            getUserId: () => authPort.getUserId(),
            getBranchId: () => authPort.getBranchId(),
        });
        const cardActionsPort: CardActionsPort = CardActionsPortFactory.createCardsActionsPort({
            getServiceUrl: () => authPort.getServiceUrl(),
            getAuthToken: () => authPort.getAuthToken(),
            getUserId: () => authPort.getUserId(),
            getBranchId: () => authPort.getBranchId(),
        });
        const ports: Ports = {
            auth: authPort,
            license : licensingPort,
            card:cardPort,
            cardActions:cardActionsPort
        };

        vue.prototype.$ports = ports;
        vue.ports = ports;
    },
};

export default portsPlugin;

// add type definitions for typescript compiler
declare module 'vue/types/vue' {
    interface Vue {
        $ports: Ports;
    }

    interface VueConstructor {
        ports: Ports;
    }
}
