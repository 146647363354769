
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import AproconFooter from '@aprocon-software/aprocon-footer';
import AproconHeader from '@aprocon-software/aprocon-header';
import Menu from '@/components/Menu.vue';
import DesktopHeader from '@/components/DesktopHeader.vue';
import {globalEventBus} from "@/main";
import PersistentDialog from "@/components/general/PersistentDialog.vue"


@Component({
    components: {
        DesktopHeader,
        AproconHeader,
        AproconFooter,
        Menu,
        PersistentDialog,
    },
})
export default class App extends Vue {
    private showStoreConfig: boolean = false;

    private smallTabs: boolean = false;
    private openInfoMenu: boolean = false;
    @Ref('header')
    private readonly header?: any;
    private initialized = false;
    private branchInfo: string = '';
    private branches: { Nummer: number, Name: string }[] = [];
    private selectedBranch: number = 0;
    @Ref()
    private messageDialog!: PersistentDialog;

    private toggleBranchCombobox() {
        const branchesCbx: any = this.$refs.branchesCbx;
        if (branchesCbx.isMenuActive) {
            (this.$refs.branchesCbx as any).isMenuActive = false;
        } else {
            (this.$refs.branchesCbx as any).isMenuActive = true;
            branchesCbx.focus();
        }
    }

    private async branchChangedByUser(): Promise<void> {
        try {
            await this.$ports.auth.switchBranch(this.selectedBranch);
            //this.$store.commit("Auth/setBranchId", this.selectedBranch.toString());
        } catch (e: any) {
            console.log(e);
        } finally {
            this.$store.commit("Auth/setBranchId", this.selectedBranch.toString());
        }
    }

    @Watch('$route')
    private async routeChanges(to: any): Promise<void> {
        const deviceConfig = this.$store.getters["Base/deviceConfig"];
        console.log(deviceConfig);
        if (!deviceConfig) {
            this.showStoreConfig = true;
        }
        if (!this.initialized && this.$route.meta?.loginRequired) {
            await this.init();
        }
    }

    private async init(): Promise<void> {
        try {
            await this.$store.dispatch('Auth/login');
            this.keepAlive();
            this.$nextTick(async () => {
                this.branches = await this.$ports.auth.getBranches();
                let selectedBranch = Number.parseInt(this.$store.getters["Auth/branchId"]);
                const item = this.branches.find((branch) => branch.Nummer === selectedBranch);
                if (!item && this.branches.length > 0) {
                    selectedBranch = this.branches[0].Nummer;
                    this.$store.commit("Auth/setBranchId", selectedBranch);
                }
                this.selectedBranch = selectedBranch;
            });
        } catch {
        }
        // get isa from query
        const query = {...this.$route.query};
        if (query.caller) {
            const caller = query.caller;
            await this.$store.commit('Base/setCaller', caller);
            delete query.caller;
            await this.$router.push(query);
        }
        globalEventBus.$on("apiIsDown", this.apiIsDown);
        globalEventBus.$on("showError", this.showError);
        globalEventBus.$on("showConfirm", this.showConfirm);

        this.initialized = true;
    }


    private keepAlive(): void {
        this.$store.dispatch('Auth/keepAlive')
            .then((success: boolean) => {
                if (success) {
                    // test credentials every 60 seconds
                    setTimeout(this.keepAlive, 1000 * 60);
                } else {
                    if (this.$store.getters["Base/caller"]) {
                        const redirectUrl = this.$store.getters["Base/caller"];
                        this.$store.commit("Base/setCaller", "");
                        window.location.href = redirectUrl;
                    } else {
                        this.$store.dispatch('Auth/login');
                    }
                }
            })
            .catch(() => {
                this.$store.dispatch('Auth/login');
            });
    }

    private menuItemClicked(): void {
        if (this.header) {
            this.header.close();
        }
    }

    private get title(): string {
        // const selectedBranch = this.$store.getters.branchId;
        if (this.$route.meta?.pageTitle) {
            return `${this.$route.meta.pageTitle}`;
        }
        return '';
    }

    private get selectedUser(): string {
        return this.$store.getters["Auth/userId"];
    }

    @Watch('selectedUser')
    private async selectedUserChanged(): Promise<void> {
        await this.init();
    }

    @Watch('selectedBranch')
    private selectedBranchChanged(): void {
        this.$store.commit("Auth/setBranchId", this.selectedBranch.toString());
        const item = this.branches.find((branch) => branch.Nummer === this.selectedBranch);
        if (item) {
            this.branchInfo = item.Name;
        } else {
            this.branchInfo = '';
        }
    }

    private apiIsDown(): void {

    }

    private showError(message: string): void {
        this.messageDialog.showError("", message);
    }

    private showConfirm(entry: { title: string, message: string, confirmationCallback: () => {} }): void {
        this.messageDialog.showConfirmation(entry.title, entry.message, entry.confirmationCallback);
    }

    async created() {
        console.log('created');
    }
    async mounted() {
        const serviceUrl = this.$store.getters["Auth/serviceUrl"];
        console.log("Url:" + serviceUrl);
        if (!serviceUrl) {
            await this.$store.dispatch("Auth/login");
        }
    }


    destroyed() {
        globalEventBus.$off("apiIsDown", this.apiIsDown);
        globalEventBus.$off("showError", this.showError);
        globalEventBus.$off("showConfirm", this.showConfirm);

    }
}
