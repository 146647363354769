
import axios, {AxiosResponse} from 'axios';
import {Mutex} from 'async-mutex';

//import Webservice from "@/assets/service/Webservice";
import Store from "@/store";
import Services from "@/assets/js/services/Services";

export default class ApiStore {
    public static AppId: string = 'APRO.GUTSCHEIN';

    // General Endpoints
    private static readonly setItemEndpoint: string = '/Storage/setItem';
    private static readonly getItemEndpoint: string = '/Storage/getItem';
    private static readonly removeItemEndpoint: string = '/Storage/removeItem';

    private static readonly clearStorageEndpoint: string = '/Storage/clearStorage';


    // Mutex to Cancel Requests
    private static getPermissionMutex: Mutex = new Mutex();

    // eslint-disable-next-line class-methods-use-this
    public static setService(service: string) {
        //Repository.defaults.baseURL = service;
        Store.commit("Auth/setServiceUrl", service);
    }

    // eslint-disable-next-line class-methods-use-this
    public static getService(): string | undefined {
        return Store.getters["Auth/serviceUrl"];
    }

    public static async getItem(key: string): Promise<any> {
        let returnValue = undefined;
        try {
            const sid = Store.getters["Auth/authToken"];
            const response = await axios.post(this.getService() + this.getItemEndpoint, {
                AppID: this.AppId,
                SID: sid,
                keyName: key
            });
            this.getErrors(response.data.getItem_response);
            returnValue = response.data?.getItem_response?.KeyValue;
        } catch (e) {

            throw(e);
        }
        return Promise.resolve(returnValue || '');
    }
    public static async setItem(key: string, value:string): Promise<void> {
        try {
            const sid = Store.getters["Auth/authToken"];
            const response = await axios.post(this.getService() + this.setItemEndpoint, {
                AppID: this.AppId,
                SID: sid,
                keyName: key,
                keyValue: value
            });
            this.getErrors(response.data.setItem_response);
            window.localStorage.setItem("deviceConfig","");
            window.localStorage.removeItem("deviceConfig");
        } catch (e) {
            console.log(e);
            throw(e);
        }
    }
    private static getErrors(response: any): void {
        if (response && response.ServiceErrors) {
            const serviceErrors = response.ServiceErrors;
            const error = serviceErrors.filter((value:any) => value.ERRORRECORDID.length === 0);
            if (serviceErrors.filter((value:any) => value.ERRORDESCRIPTION.includes('Anmeldung erforderlich')).length > 0) {
                Store.commit("Auth/setLoggedIn",false);
            } else {
                throw {
                    message: serviceErrors[0].ERRORDESCRIPTION
                };
            }
        }
    }
}
