import { AuthPort } from './AuthPort';
import { AuthService } from './services/AuthService';
import type { StoreRepository } from './adapter/StoreRepository';
import type { AuthRepository } from './adapter/AuthRepository';
import type { LoginRepository } from './adapter/LoginRepository';
import { AuthRepositoryImpl } from './adapter/impl/AuthRepositoryImpl';
import { LoginRepositoryImpl } from './adapter/impl/LoginRepositoryImpl';
import { LocalStorageRepository } from './adapter/impl/LocalStorageRepository';

export class AuthPortFactory {

    private readonly appId: string;
    private readonly loginServiceUrl: string;
    private readonly store: StoreRepository;

    private authService: AuthService | null = null;
    private authRepository: AuthRepository | null = null;
    private loginRepository: LoginRepository | null = null;

    private constructor(params?: { appId?: string; loginServiceUrl?: string; store?: StoreRepository }) {
        this.appId = params?.appId || 'APRO.KASSENBUCH';
        this.loginServiceUrl = params?.loginServiceUrl || 'https://auth.aprocon.de';
        this.store = params?.store || new LocalStorageRepository();
    }

    /**
     * Create a Auth Port instance
     * @param params
     */
    public static createAuthPort(params: { appId: string; loginServiceUrl: string; store?: StoreRepository }): AuthPort {
        const factory = new AuthPortFactory(params);
        return new AuthPort({
            authService: factory.getAuthService(),
        });
    }

    private getAuthService(): AuthService {
        if (this.authService === null) {
            this.authService = new AuthService({
                authRepository: this.getAuthRepository(),
                loginRepository: this.getLoginRepository(),
                appId: this.appId,
            });
        }
        return this.authService;
    }

    private getAuthRepository(): AuthRepository {
        if (this.authRepository === null) {
            this.authRepository = new AuthRepositoryImpl({
                appId: this.appId,
                store: this.store,
            });
        }
        return this.authRepository;
    }

    private getLoginRepository(): LoginRepository {
        if (this.loginRepository == null) {
            this.loginRepository = new LoginRepositoryImpl({
                loginUrl: this.loginServiceUrl,
            });
        }
        return this.loginRepository;
    }
}
